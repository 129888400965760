export const NEW_ORDER = "/client/new_order";
export const SERVICES = "/client/services";
export const AFFILIATES = "/client/affiliates";
export const ORDERS = "/client/orders";
export const ADD_FUNDS = "/client/add_funds";
export const API = "/client/api";
export const MASS_ORDER = "/client/mass_order";
export const LOGIN = "/client/login";
export const ACCOUNT_SETTING = "/client/account_setting";
export const DEFAULT = "/";
export const NOT_FOUND = "*";
